// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "t_tn d_gv d_cs";
export var heroHeaderCenter = "t_gw d_gw d_cs d_dw";
export var heroHeaderRight = "t_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "t_tp d_gr d_cw";
export var heroParagraphCenter = "t_gs d_gs d_cw d_dw";
export var heroParagraphRight = "t_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "t_tq d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "t_tr d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "t_ts d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "t_tt d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "t_tv d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "t_rK q_rK";
export var heroExceptionNormal = "t_rL q_rL";
export var heroExceptionLarge = "t_rM q_rM";
export var Title1Small = "t_rp q_rp q_qV q_qW";
export var Title1Normal = "t_rq q_rq q_qV q_qX";
export var Title1Large = "t_rr q_rr q_qV q_qY";
export var BodySmall = "t_rz q_rz q_qV q_rc";
export var BodyNormal = "t_rB q_rB q_qV q_rd";
export var BodyLarge = "t_rC q_rC q_qV q_rf";